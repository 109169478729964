<template>
  <v-app>
    

    <v-main>
      <v-container fill-height>

      <v-row 
        align="center"
        justify="center">
      <v-col>
        <v-text-field label="Message" v-model="message" outlined
        
          :disabled="isInputDisabled"
        ></v-text-field>
        <v-btn @click="onHoleInOne"
          :disabled="isButtonDisabled"
          elevation="2"
          x-large
        >HOLE in ONE</v-btn>

      </v-col>
  </v-row>
  
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
    // HelloWorld,
  },

  methods: {
    onHoleInOne() {
      // this.holeInOneActive = true;
      const baseUrl = process.env.VUE_APP_API_URL;
      this.$http.post(baseUrl + '/send-notification',{message:this.message});  
      this.$confetti.start();
      this.message = null;
      this.isInputDisabled = true;
      setTimeout(()=>{
        this.$confetti.stop();
        this.isInputDisabled = false;
      },10*1000);
    },
  },

  computed: {
    isButtonDisabled() {
      return this.message === null;
    },
  },

  data: () => ({
    message: 'I did it :D',
    isInputDisabled: false,
  }),
};
</script>
